/* Importing Google Font 'Poppins' */
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);

/* General body styling */
body {
  margin: 0;
  /* Using Poppins font from Google Fonts for the entire page */
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased; /* Smoothing font for better appearance on Webkit browsers */
  -moz-osx-font-smoothing: grayscale; /* Smoothing font for better appearance on Firefox */
}




/* Styling for code blocks */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; /* Monospaced fonts for code readability */
}
li {
  list-style: none !important;
}

/* Header section styling */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px; /* Padding for header area */
  background-color: #fff;
  position: sticky; /* Makes the header stick to the top of the page */
  top: 0;
  z-index: 100; /* Ensures the header is above other elements */
}

/* Additional styling when the header is fixed */
.header.fixed {
  box-shadow: 3px 2px 4px 0 #d5d2d2; /* Adds shadow for better visibility */
}

/* Container for header content */
.header-container {
  max-width: 1320px; /* Maximum width for the container */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}

/* Styling for close button (initially hidden) */
.close-btn {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  border: 0;
  background: 0;
  font-size: 29px;
}

/* Styling for the left container within the header */
.left-container {
  align-items: center;
  width: 30%;
}

/* Logo styling within the left container */
.left-container .logo {
  width: 100%; /* Full width for responsiveness */
  max-width: 200px; /* Maximum width for the logo */
  height: auto; /* Maintain aspect ratio */
}

/* Menu icon styling (initially hidden) */
.menu-icon {
  display: none;
  cursor: pointer;
}

/* Image within menu icon */
.menu-icon img {
  width: 20px;
  height: 20px;
}

/* Right container styling within the header */
.right-container {
  display: flex;
  align-items: center;
  width: 70%;
  padding-left: 20px; /* Padding for better spacing */
  justify-content: flex-end;
  flex-wrap: wrap; /* Allows wrapping for responsiveness */
  transition: transform 0.3s ease-in-out; /* Smooth transition for transformations */
}

/* Additional styling when the right container is open */
.right-container.open {
  transform: translateX(0); /* Makes the container visible */
}

/* Styling for navigation links */
.nav-link {
  text-decoration: none;
  color: #333;
  margin-right: 10px;
  font-size: 15px;
  font-weight: 500;
  margin-left: 5px;
  padding: 5px 15px 7px;
  animation: 0.3s ease-in-out; /* Animation for smooth transitions */
  transition: 0.3s ease-in-out; /* Transition for hover effects */
  cursor: pointer;
}

/* Hover effect for navigation links */
.nav-link:hover {
  color: #69DC9E;
  animation: 0.3s ease-in-out; /* Animation for smooth transitions */
  transition: 0.3s ease-in-out; /* Transition for hover effects */
}

/* Styling for contact us button */
.contactus-btn {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
}

/* Hover effect for button styled as navigation link */
button.nav-link:hover {
  background: #69DC9E; /* Background color change on hover */
}

/* Styling for button navigation link */
button.nav-link {
  background: #ff1a41;
  border-radius: 3px !important;
  color: #fff !important;
  margin-left: 20px !important; /* Spacing for responsiveness */
  padding: 7px 10px !important;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  width: 150px;
  height: 40px;
  animation: 0.3s ease-in-out; /* Animation for smooth transitions */
  transition: 0.3s ease-in-out; /* Transition for hover effects */
}









.about-heading-passage {
  text-align: center; /* Center the text */
  margin: 20px auto; /* Optional: adds some space around the section */
  max-width: 800px; /* Optional: limits the width for better readability */
}

.about-heading-passage p {
  font-size: 1.5em; /* Adjust size for the heading */
  margin: 0; /* Remove default margin */
}

.about-heading-passage span {
  display: block; /* Makes the span a block element for proper spacing */
  font-size: 1.2em; /* Adjust size for the description */
  margin-top: 10px; /* Adds space between the heading and description */
}



/* rajesh added code for bottom image section */



/* end of bottom image section */














/* Styling for SVG icons within button navigation links */
button.nav-link svg {
  width: 16px;
  height: 16px;
  margin-left: 10px;
}

/* Styling for SVG paths within button navigation links */
button.nav-link svg path {
  color: #fff;
  fill: currentColor; /* Inherits color from parent */
}

/* Removing default margin and padding for unordered lists within buttons */
button.nav-link ul {
  margin: 0;
  padding: 0;
}

/* Styling for list items within buttons */
button.nav-link ul li {
  list-style: none;
  width: 20px;
  height: 12px;
  transition: .5s; /* Smooth transition */
  margin-left: 0px;
}

/* Hover effect for list items within buttons */
button.nav-link:hover ul li {
  margin-left: 10px; /* Moves list item on hover */
}

/* Styling for spans within list items in buttons */
button.nav-link ul li span {
  position: absolute;
  width: 10px;
  height: 10px;
  border: 2.5px solid #fff;
  transition: .5s; /* Smooth transition */
}

/* Additional styling for 'next' class spans within buttons */
button.nav-link ul li.next span {
  transform: rotate(-45deg);
  border-left: none;
  border-top: none;
}

/* Styling for before pseudo-element in 'next' class spans within buttons */
button.nav-link ul li.next span:before {
  content: '';
  position: absolute;
  width: 16px;
  height: 2.5px;
  background: #fff;
  transform-origin: right;
  transform: rotate(45deg) translate(-4px, 13.5px) scale(0);
  transition: .5s; /* Smooth transition */
}

/* Hover effect for before pseudo-element in 'next' class spans within buttons */
button.nav-link:hover ul li.next span:before {
  transform: rotate(45deg) translate(-4px, 13.5px) scale(1); /* Scale up on hover */
}
/* First section css */

/* Modal styles */
.modalBG {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background for modal overlay */
  z-index: 1000; /* Ensures the modal is above other elements */
}

.modalBG.none {
  display: none; /* Hides the modal overlay when not in use */
}

.modalWhite {
  background: #fff; /* Background color for modal content */
  padding: 0px;
  border-radius: 5px; /* Rounded corners for modal */
  max-width: 600px;
  width: 90%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Box shadow for depth effect */
}

.top-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}

.form-view .mb-3 {
  margin-bottom: 15px;
}

.form-view .form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.india-img {
  position: absolute;
  top: 12px;
  left: 10px;
  width: 24px;
  height: auto;
}

.line {
  position: absolute;
  top: 12px;
  left: 40px;
  height: 24px;
  width: 1px;
  background: #ccc;
}

.updates {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.updates input {
  margin-right: 10px;
}

.form-btn {
  background: #ff1a41;
  border: none;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  /* Responsive adjustments for header container */
  .header-container {
    padding: -1px 15px;
  }

  .left-container {
    width: 35%;
  }

  .right-container {
    width: 131%; /* Adjusted width for responsiveness */
  }
  .social-icons {
    justify-content: space-between;
    display: flex;
  }
  .col img {
    width: 270px !important;
    height: 76px !important;
  }
}

@media (max-width: 992px) {
  /* Responsive adjustments for logo width */
  .left-container .logo {
    width: 150px;
  }

  /* Responsive adjustments for right container */
  .right-container {
    flex-direction: row;
    align-items: center;
    padding-left: 0;
    margin-top: 0px;
  }

  /* Responsive adjustments for navigation links */
  .nav-link, button.nav-link {
    margin-left: 0 !important;
    margin-right: 0;
    margin-bottom: 10px;
  }

  /* Responsive adjustments for contact us button */
  .contactus-btn {
    margin-top: 10px;
  }
  .social-icons {
    justify-content: space-between;
    display: flex;
  }
  .col img {
    width: 270px !important;
    height: 76px !important;
  }
}

@media (max-width: 768px) {
  /* Responsive adjustments for header */
  .header {
    flex-direction: column;
    padding: 15px;
    height: 65px;
    padding-top: 10px;
  }
  /* Display close button on smaller screens */
  .close-btn {
    display: block;
  }
  /* Responsive adjustments for header container */
  .header-container {
    max-width: 1686px;
    flex-direction: row;
    align-items: center;
    margin-top: 3px;
  }

  .left-container {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
  }

  .menu-icon {
    display: block;
    margin-right: -9px;
  }

  /* Styling for right container on smaller screens */
  .right-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: 0;
    left: -100%;
    background-color: #fff;
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    box-shadow: -11px 0px 15px rgba(0, 0, 0, 0.3);
  }

  /* Display right container when open */
  .right-container.open {
    transform: translateX(100%);
  }

  /* Responsive adjustments for right container */
  .right-container {
      width: 100%;
      justify-content: flex-start;
      padding-top: 76px; /* Adjusted padding for header */
  }

  /* Responsive adjustments for navigation links */
  .nav-link, button.nav-link {
      width: 100%;
      text-align:left;
  }
  .social-icons {
    justify-content: space-between;
    display: flex;
  }
  .col img {
    width: 270px !important;
    height: 76px !important;
  }
}

@media (max-width: 576px) {
  /* Responsive adjustments for logo width */
  .left-container .logo {
      width: 150px;
  }

  /* Responsive adjustments for right container */
  .right-container {
      flex-direction: column;
      align-items: center;
  }

  /* Responsive adjustments for navigation links */
  .nav-link, button.nav-link {
      width: 100%;
      margin: 5px 0;
  }
  .social-icons {
    justify-content: space-between;
    display: flex;
  }
  .col img {
    width: 270px !important;
    height: 76px !important;
  }
}

/* Second section css */
.carousel {
  position: relative;
}

.carousel-inner {
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.raj_sub_head_text h2 {
  font-size: 24px; /* Adjust the size as needed */
}

.carousel-item img {
  width: 100%;
  height: auto;
}
/* Control buttons */
.carousel-control-prev, .carousel-control-next {
  /* width: 5%; */
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  /* background-color: rgba(0, 0, 0, 0.5); */
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
.carousel-control-prev-icon:hover,
.carousel-control-next-icon:hover {
  /* background-color: rgba(0, 0, 0, 0.8); */
}
/* Indicators */
.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.carousel-indicators li {
  /* background-color: rgba(255, 255, 255, 0.5); */
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  cursor: pointer;
}
.carousel-indicators .active {
  /* background-color: rgba(255, 255, 255, 1); */
}
/* Responsive adjustments */
@media (max-width: 768px) {
  .carousel-control-prev,
  .carousel-control-next,
  .carousel-indicators {
    display: none;
  }
  .carousel-item img {
    height: 300px; /* Adjust this value as needed */
  }
}
@media (max-width: 576px) {
  .carousel-control-prev,
  .carousel-control-next,
  .carousel-indicators {
    display: none;
  }
  .carousel-item img {
    height: 170px; /* Adjust this value as needed */
  }
}
/* Second section css */
  
/* Third section css */
.third-section {
  text-align: center; /* Center-aligns the content */
  padding: 50px 0; /* Adds padding to the top and bottom */
  background-color: #f1f1f1; /* Sets the background color */
}

.section-heading {
  font-size: 36px; /* Sets the font size */
  font-weight: 700; /* Sets the font weight */
  margin-bottom: 0px; /* Removes bottom margin */
  color: #000000; /* Sets the text color */
}

.section-subheading {
  /* font-size: 18px; */
  margin-bottom: 30px; /* Adds margin at the bottom */
  color: #ff1a41; /* Sets the text color */
}

.box2-container {
  display: flex; /* Displays elements in a row */
  justify-content: center; /* Centers the elements horizontally */
  max-width: 1320px; /* Sets the maximum width */
  margin: 0 auto; /* Centers the container horizontally */
}

.box2 {
  align-items: center; /* Centers items vertically */
  background: #f1f1f1; /* Sets the background color */
  border: 0; /* Removes border */
  border-radius: 5px; /* Adds border radius */
  box-shadow: none; /* Removes box shadow */
  display: flex; /* Displays elements in a row */
  flex-direction: column; /* Arranges items in a column */
  margin-left: 10px; /* Adds margin to the left */
  padding: 30px 30px; /* Adds padding */
  width: 100%; /* Sets the width to 100% */
  transition: 0.3s ease-in-out; /* Adds transition effect */
}

.box2:hover .box-title {
  color: #69DC9E; /* Changes color on hover */
}

.box2 img {
  width: 100px; /* Sets the width of the image */
  height: 100px; /* Sets the height of the image */
  border-radius: 50%; /* Makes the image circular */
  margin-bottom: 15px; /* Adds margin at the bottom */
  align-items: center;
  background: #fff;
  border-radius: 5px;
  display: flex;
  height: 80px;
  justify-content: center;
  /* margin: -80px 0 1rem; */
  width: 80px;
  padding: 10px;
}

.box2-title {
  font-size: 16px; /* Sets the font size */
  font-weight: 900; /* Sets the font weight */
  margin-bottom: 10px; /* Adds margin at the bottom */
  color: #000; /* Sets the text color */
}

.box2-subtitle {
  /* font-size: 16px; */
  color: #000; /* Sets the text color */
}




























.box3-container {
  display: flex; /* Displays elements in a row */
  justify-content: center; /* Centers the elements horizontally */
  max-width: 1320px; /* Sets the maximum width */
  margin: 0 auto; /* Centers the container horizontally */
}

.box3 {
  align-items: center; /* Centers items vertically */
  background: #ffffff; /* Sets the background color */
  border: 0; /* Removes border */
  border-radius: 5px; /* Adds border radius */
  box-shadow: none; /* Removes box shadow */
  display: flex; /* Displays elements in a row */
  flex-direction: column; /* Arranges items in a column */
  margin-left: 10px; /* Adds margin to the left */
  padding: 30px 3px; /* Adds padding */
  width: 100%; /* Sets the width to 100% */
  transition: 0.3s ease-in-out; /* Adds transition effect */
}

.box3:hover .box-title {
  color: #69DC9E; /* Changes color on hover */
}

.box3 img {
  width: 100px; /* Sets the width of the image */
  height: 100px; /* Sets the height of the image */
  border-radius: 50%; /* Makes the image circular */
  margin-bottom: 15px; /* Adds margin at the bottom */
  align-items: center;
  /* background: #fff; */  
  border-radius: 5px;
  display: flex;
  height: 100px;
  justify-content: center;
  /* margin: -80px 0 1rem; */
  width: 100px;
  padding: 10px;
}

.box3-title {
  font-size: 16px; /* Sets the font size */
  font-weight: 900; /* Sets the font weight */
  margin-bottom: 10px; /* Adds margin at the bottom */
  color: #000; /* Sets the text color */
}

.box3-subtitle {
  /* font-size: 16px; */
  color: #000; /* Sets the text color */
}














/* Third section css */

/* Fourth section css */
.fourth-section {
  text-align: center; /* Centers the content */
  padding: 50px 0; /* Adds padding to the top and bottom */
  /* background-color: #faf5f7;  */
  background-color: #ffffff; /* Sets the background color */
}

.fourth-section {
  padding: 20px;
}

.section-heading {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;
}

.section-subheading {
  text-align: center;
  margin-bottom: 2rem;
}

/* .box2-container {
  display: flex;
  justify-content: center;
} */

.service-area {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 60%;
}

.centered {
  text-align: center;
}

.form__field--theme {
  width: 160px;
  background: #ffffff30;
  border-radius: 4px;
  padding: 8px 5px;
  border: 1px solid #ddd;
  margin-top: -37px;
  color: #ff1a41;
}

.form__field--theme:focus-visible {
  border-color: #ccc !important;
}

.packages {
  display: none;
  background-color: transparent;
}

.packages.visible {
  display: block;
  width: 100%;
}

.package {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #ff1a41;
  /* margin: 0 2rem; */
  padding: 1.5rem 0 .5rem 0;
  margin-left: 8px;
}

.package-name {
  font-size: 16px;
  color: #000;
  font-weight: 500;
}

.package-price {
  font-size: 16px;
  color: #000;
}

.button-container {
  /* margin-top: 1rem; */
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 45px;
}

.section-button {
  background-color: #ff1a41;
  color: #fff;
  padding: .5rem 2.5rem;
  border: none;
  font-weight: 600;
  cursor: pointer;
}

.section-button ul {
  list-style: none;
  padding: 0;
}

.section-button li.next span {
  /* Styles for button animation */
}

.tnc {
  position: absolute;
  right: 400px;
}

/* Responsive styling */
@media (max-width: 1200px) {
  .service-area {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .service-area {
    width: 90%;
  }

  .tnc {
    right: 20px;
  }
  footer .row .col:nth-child(2), .col:nth-child(3) {
    flex-basis: 27% !important; /* Sets the flex basis for specific columns */
  }
}

@media (max-width: 576px) {
  .service-area {
    width: 100%;
    display: ruby;
  }

  .tnc {
    right: 10px;
    margin-top: 111px;
    font-weight: 600;
    font-size: 13px;
  }
}

.box-container {
  display: flex; /* Displays elements in a row */
  justify-content: center; /* Centers the elements horizontally */
  margin-top: 50px; /* Adds margin to the top */
}

.box {
  width: 600px; /* Sets the width of the box */
  padding: 20px; /* Adds padding inside the box */
  margin: 0 20px; /* Adds margin around the box */
  background-color: #fff0; /* Sets the background color of the box */
  border-radius: 10px; /* Adds border radius */
  box-shadow: none; /* Removes box shadow */
}

.box img {
  width: 200px; /* Sets the width of the image */
  height: 200px; /* Sets the height of the image */
  /* border-radius: 50%; Makes the image circular */
  margin-bottom: 15px; /* Adds margin at the bottom */
}

.box-paragraph {
  font-size: 16px; /* Sets the font size */
  color: #333; /* Sets the text color */
  text-align: justify;
}
.HowItWorks_stepsBx__HY4oU {
  align-items: center; /* Centers items vertically */
  display: flex; /* Displays elements in a row */
  justify-content: space-between; /* Distributes space between items */
  margin: 0 auto; /* Centers the container horizontally */
  max-width: 600px; /* Sets the maximum width of the container */
  position: relative; /* Sets the positioning context */
  transition: 0.2s ease-in; /* Adds transition effect */
  width: 100%; /* Sets the width of the container */
  padding: 0; /* Removes padding */
}

.pricing ul {
  color: #6c757d; /* Sets the text color */
  line-height: 20px; /* Sets the line height */
  list-style: none; /* Removes default list styles */
  padding: 20px 0; /* Adds padding */
  text-align: left; /* Aligns text to the left */
}

.HowItWorks_stepsBx__HY4oU li,
.HowItWorks_stepsBx__HY4oU li span {
  transition: 0.2s ease-in; /* Adds transition effect */
}

.HowItWorks_stepsBx__HY4oU li {
  background-color: #f0f0f0; /* Sets the background color */
  height: 32px; /* Sets the height */
  position: relative; /* Sets the positioning context */
  width: 32px; /* Sets the width */
  z-index: 1; /* Sets the stacking order */
}
.HowItWorks_stepsBx__HY4oU li.HowItWorks_active__4JMUw span {
  color: #fff; /* Sets the text color */
  background: #69DC9E; /* Sets the background color */
}

.HowItWorks_stepsBx__HY4oU li label {
  margin-left: -50px; /* Adjusts the position */
  position: absolute; /* Sets the positioning context */
  left: 50%; /* Moves to the center horizontally */
  bottom: -30px; /* Moves down from the bottom */
  white-space: nowrap; /* Prevents text wrapping */
  width: 100px; /* Sets the width */
  font-size: 12px; /* Sets the font size */
  text-align: center; /* Centers the text */
}
.HowItWorks_stepsBx__HY4oU li.HowItWorks_active__4JMUw label {
  /* background-color: #002dfb; */
  color: #000;
  animation: 0.2s ease-in-out;
  transition: 0.2s ease-in;
}
.HowItWorks_stepsBx__HY4oU:after {
  content: ""; /* Adds content */
  position: absolute; /* Sets the positioning context */
  left: 0px; /* Positions from the left edge */
  top: 50%; /* Positions from the top edge */
  height: 1px; /* Sets the height */
  background-color: #666; /* Sets the background color */
  width: 99%; /* Sets the width */
}
.howItWork
  .howitwork-container
  .step-by-step-how-it-work
  .HowItWorks_stepsBx__HY4oU {
  margin: 0 auto; /* Centers the container horizontally */
  display: flex; /* Displays elements in a row */
  align-items: center; /* Centers items vertically */
  justify-content: space-between; /* Distributes space between items */
  position: relative; /* Sets the positioning context */
  max-width: 600px; /* Sets the maximum width of the container */
  width: 100%; /* Sets the width of the container */
  animation: 0.2s ease-in-out; /* Adds animation */
  transition: 0.2s ease-in; /* Adds transition effect */
}

.HowItWorks_stepsBx__HY4oU li,
.HowItWorks_stepsBx__HY4oU li span {
  border-radius: 50px; /* Rounds the corners */
  display: flex; /* Displays elements in a row */
  align-items: center; /* Centers items vertically */
  justify-content: center; /* Centers items horizontally */
  animation: 0.2s ease-in-out; /* Adds animation */
  transition: 0.2s ease-in; /* Adds transition effect */
}

.HowItWorks_active__4JMUw {
  background-color: #69DC9E !important; /* Sets the background color */
}

.HowItWorks_stepsBx__HY4oU li {
  width: 32px; /* Sets the width */
  height: 32px; /* Sets the height */
  position: relative; /* Sets the positioning context */
  background-color: #f0f0f0; /* Sets the background color */
  z-index: 1; /* Sets the stacking order */
  animation: 0.2s ease-in-out; /* Adds animation */
  transition: 0.2s ease-in; /* Adds transition effect */
  border: 1px solid #ccc; /* Adds border */
}

.HowItWorks_stepsBx__HY4oU li span {
  font-size: 12px; /* Sets the font size */
  width: 22px; /* Sets the width */
  height: 22px; /* Sets the height */
  color: #000; /* Sets the text color */
  animation: 0.2s ease-in-out; /* Adds animation */
  transition: 0.2s ease-in; /* Adds transition effect */
}

.HowItWorks_stepsBx__HY4oU li label {
  margin-left: -50px; /* Adjusts the position */
  position: absolute; /* Sets the positioning context */
  left: 50%; /* Moves to the center horizontally */
  bottom: -30px; /* Moves down from the bottom */
  white-space: nowrap; /* Prevents text wrapping */
  width: 100px; /* Sets the width */
  font-size: 12px; /* Sets the font size */
  text-align: center; /* Centers the text */
  animation: 0.2s ease-in-out; /* Adds animation */
  transition: 0.2s ease-in; /* Adds transition effect */
}

.HowItWorks_stepsBx__HY4oU:after {
  content: ""; /* Adds content */
  position: absolute; /* Sets the positioning context */
  left: 0px; /* Positions from the left edge */
  top: 50%; /* Positions from the top edge */
  height: 1px; /* Sets the height */
  background-color: #666; /* Sets the background color */
  width: 99%; /* Sets the width */
  animation: 0.2s ease-in-out; /* Adds animation */
  transition: 0.2s ease-in; /* Adds transition effect */
}
/* fourth section css */

/* fifth section css */
.fifth-section {
  text-align: center; /* Aligns text to the center */
  padding: 50px 0; /* Adds padding */
  background-color: #f1f1f1; /* Sets the background color */
}

.section-heading {
  font-size: 36px; /* Sets the font size */
  font-weight: 700; /* Sets the font weight */
  margin-bottom: 0px; /* Sets the bottom margin */
  text-align: center;
}

.section-subheading {
  margin-bottom: 30px; /* Sets the bottom margin */
  text-align: center;
}

.box-container1 {
  display: flex; /* Displays elements in a row */
  justify-content: center; /* Centers content horizontally */
  max-width: 1320px; /* Sets the maximum width */
  margin: 0 auto; /* Centers container horizontally */
  margin-top: 70px; /* Adds top margin */
}

.box-container1 .our-services-box {
  position: relative; /* Sets the positioning context */
  cursor: pointer; /* Changes cursor to pointer on hover */
  transition: 0.3s ease-in-out; /* Adds transition effect */
  background-color: #ffffff; /* Sets the background color */
}

.our-services-box {
  border: 1px solid #ccc; /* Adds border */
  border-radius: 5px; /* Rounds the corners */
  margin-right: 35px; /* Adds right margin */
  width: 200px; /* Sets the width */
  height: 200px; /* Sets the height */
  padding: 15px; /* Adds padding */
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  display: grid; /* Sets display to grid */
  transition: 0.5s ease-in-out; /* Adds transition effect */
}

.our-services-box:hover {
  transform: scale(1.1); /* Scales the box on hover */
  margin-right: 20px; /* Adjusts margin on hover */
  margin-left: 10px; /* Adjusts margin on hover */
  transition: 0.3s ease-in-out; /* Adds transition effect */
}

.our-services-box:hover .our-services-title {
  color: #69DC9E; /* Changes text color on hover */
  transition: 0.3s ease-in-out; /* Adds transition effect */
}

.box1-img {
  border-radius: 4px; /* Rounds the corners */
  width: 100px; /* Sets the width */
  height: 100px; /* Sets the height */
}

.our-services-title {
  font-size: 19px; /* Sets the font size */
  font-weight: 900; /* Sets the font weight */
  margin-bottom: 10px; /* Sets the bottom margin */
  color: #010400; /* Sets the text color */
  text-align: center; /* Centers text horizontally */
  position: relative; /* Sets the positioning context */
}

.our-services-content .our-services-subtitle {
  color: #fff; /* Sets the text color */
  text-align: justify; /* Justifies the text */
  position: relative; /* Sets the positioning context */
}

.box1 {
  width: 400px; /* Sets the width */
  padding: 20px; /* Adds padding */
  margin: 0 20px; /* Adds margin */
  background-color: #fff; /* Sets the background color */
  border-radius: 10px; /* Rounds the corners */
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1); /* Adds box shadow */
}

.box1 img {
  width: 100px; /* Sets the width */
  height: 100px; /* Sets the height */
  border-radius: 7%; /* Rounds the corners */
  margin-bottom: 15px; /* Sets the bottom margin */
  margin-top: -60px; /* Adjusts top margin */
}

.box-title {
  font-size: 19px; /* Sets the font size */
  font-weight: 900; /* Sets the font weight */
  margin-bottom: 10px; /* Sets the bottom margin */
  color: #000; /* Sets the text color */
  transition: 0.3s ease-in-out; /* Adds transition effect */
}

.box-subtitle {
  color: #000; /* Sets the text color */
  text-align: center; /* Justifies the text */
}

.box2 .box-title {
  font-size: 16px; /* Sets the font size */
  font-weight: 900; /* Sets the font weight */
  margin-bottom: 10px; /* Sets the bottom margin */
  color: #000; /* Sets the text color */
}

.box2 .box-subtitle {
  color: #000; /* Sets the text color */
  text-align: justify; /* Justifies the text */
}
/* fifth section css */

/* sixth section css */
.sixth-section {
  text-align: center; /* Aligns text to the center */
  padding: 50px 0; /* Adds padding */
  /* background-color: #faf5f7;  */
  background-color: #f1f1f1; /* Sets background color */
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  display: grid; /* Sets display to grid */
  align-content: center; /* Centers content vertically */
  justify-items: center; /* Centers content horizontally */
}

.sixth-section .section-heading {
  font-size: 28px; /* Sets the font size */
  font-weight: 700; /* Sets the font weight */
  margin-bottom: .5rem; /* Sets the bottom margin */
  color: #333; /* Sets the text color */
}

.sixth-section .section-subheading {
  /* font-size: 18px; */
  font-weight: 500; /* Sets the font weight */
  /* margin-bottom: 30px; */
  color: #666; /* Sets the text color */
}

.section-button {
  text-decoration: none; /* Removes default text decoration */
  color: #333; /* Sets the text color */
  margin-right: 10px; /* Sets the right margin */
  font-size: 15px; /* Sets the font size */
  font-weight: 500; /* Sets the font weight */
  margin-left: 5px; /* Sets the left margin */
  padding: 5px 15px 7px; /* Adds padding */
  animation: 0.3s ease-in-out; /* Adds animation effect */
  transition: 0.3s ease-in-out; /* Adds transition effect */
}

.section-button:hover {
  color: #69DC9E; /* Changes text color on hover */
  animation: 0.3s ease-in-out; /* Adds animation effect */
  transition: 0.3s ease-in-out; /* Adds transition effect */
}

button.section-button:hover {
  background: #69DC9E; /* Changes background color on hover */
}

button.section-button {
  /* background: linear-gradient(63deg, #4162ff, #4162ff 18.33%, #5473ff 28.33%, #5473ff 53.66%, #6b83f0 63.66%, #6b83f0); */
  background: #ff1a41; /* Sets the background color */
  border-radius: 3px !important; /* Sets border radius */
  color: #fff !important; /* Sets the text color */
  /* margin-left: 82px !important; */
  padding: 7px 10px !important; /* Adds padding */
  display: flex; /* Sets display to flex */
  font-size: 15px; /* Sets the font size */
  font-weight: 500; /* Sets the font weight */
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  border: none; /* Removes border */
  cursor: pointer; /* Changes cursor to pointer */
  width: 212px; /* Sets the width */
  height: 40px; /* Sets the height */
  animation: 0.3s ease-in-out; /* Adds animation effect */
  transition: 0.3s ease-in-out; /* Adds transition effect */
}

button.section-button svg {
  width: 16px; /* Sets the width */
  height: 16px; /* Sets the height */
  margin-left: 10px; /* Sets the left margin */
}

button.section-button svg path {
  color: #fff; /* Sets the text color */
  fill: currentColor; /* Sets the fill color */
}

button.section-button ul {
  margin: 0; /* Removes margin */
  padding: 0; /* Removes padding */
}

button.section-button ul li {
  list-style: none; /* Removes default list style */
  width: 20px; /* Sets the width */
  height: 12px; /* Sets the height */
  /* background-color: #262626; */
  /* margin: 0 10px; */
  /* border-radius: 50%; */
  transition: .5s; /* Adds transition effect */
  margin-left: 0px; /* Sets the left margin */
}

button.section-button:hover ul li {
  margin-left: 10px; /* Sets the left margin on hover */
}

button.section-button ul li span {
  position: absolute; /* Sets the positioning context */
  width: 10px; /* Sets the width */
  height: 10px; /* Sets the height */
  border: 2.5px solid #fff; /* Sets border */
  transition: .5s; /* Adds transition effect */
}

button.section-button ul li.next span {
  /* top: 50%; */
  /* right: 20%; */
  transform: rotate(-45deg); /* Rotates the element */
  border-left: none; /* Removes left border */
  border-top: none; /* Removes top border */
}

button.section-button ul li.next span:before {
  content: ''; /* Adds content */
  position: absolute; /* Sets the positioning context */
  width: 16px; /* Sets the width */
  height: 2.5px; /* Sets the height */
  background: #fff; /* Sets background color */
  transform-origin: right; /* Sets the transform origin */
  transform: rotate(45deg) translate(-4px, 13.5px) scale(0); /* Applies transformation */
  transition: .5s; /* Adds transition effect */
}

button.section-button:hover ul li.next span:before {
  transform: rotate(45deg) translate(-4px, 13.5px) scale(1); /* Applies transformation on hover */
}
/* sixth section css */
/* .sixth-section .section-button {
  background: #000;
  border-radius: 3px !important;
  color: #fff !important;
  padding: 7px 10px !important;
  font-size: 15px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  width: 100px;
  height: 40px;
}
.sixth-section .section-button:hover {
  background-color: #0056b3;
} */
/* sixth section css */

/* seventh section css */
/* seventh section css */
.seventh-section {
  text-align: center; /* Center-aligns text content */
  padding: 50px 0; /* Adds vertical padding of 50px and no horizontal padding */
  background-color: #f1f1f1; /* Applies a light gray background */
}

/* Keyframes animation for sliding effect */
@keyframes slide {
  from {
    transform: translateX(0); /* Starts the slide animation from the current position */
  }
  to {
    transform: translateX(-100%); /* Moves the element to the left by 100% of its width */
  }
}

/* Styling for the brand element */
.brand {
  overflow: hidden; /* Hides overflowing content */
  padding: 60px 0; /* Adds vertical padding of 60px and no horizontal padding */
  white-space: nowrap; /* Prevents wrapping of text */
  position: relative; /* Positioned relative to its normal position */
}

/* Styling for the pseudo-elements before and after .brand */
.brand::before,
.brand::after {
  content: ""; /* Generates pseudo-elements */
  position: absolute; /* Positioned relative to the brand element */
  top: 0; /* Places the pseudo-elements at the top of the brand element */
  width: 200px; /* Sets the width of the pseudo-elements */
  height: 100%; /* Sets the height of the pseudo-elements to match the brand element */
  background: linear-gradient(to left, rgba(255,255,255, 0), rgb(255, 255, 255)); /* Applies a linear gradient from transparent to white */
  z-index: 2; /* Places the pseudo-elements above the content */
}

/* Styling for the before pseudo-element */
.brand::before {
  left: 0; /* Positions the before pseudo-element at the left edge of the brand element */
  background: linear-gradient(to left, rgba(255,255,255, 0), rgb(255, 255, 255)); /* Applies a linear gradient from transparent to white */
}

/* Styling for the after pseudo-element */
.brand::after {
  right: 0; /* Positions the after pseudo-element at the right edge of the brand element */
  background: linear-gradient(to right, rgba(255,255,255, 0), rgb(255, 255, 255)); /* Applies a linear gradient from transparent to white */
}

/* Pauses the slide animation on hover */
.brand:hover .brand-slide {
  animation-play-state: paused; /* Pauses the slide animation */
}

/* Styling for the brand-slide class */
.brand-slide {
  display: inline-block; /* Displays the brand-slide as an inline-block element */
  animation: 35s slide infinite linear; /* Applies the slide animation with a duration of 35s and repeats infinitely */
}

/* Styling for the images within the brand-slide */
.brand-slide img {
  height: 80px; /* Sets the height of the images */
  margin: 0 40px; /* Adds margin to the left and right of the images */
  filter: grayscale(0); /* Removes grayscale effect on the images */
}
/* seventh section css */

/* eighty section css *//* eighth section css */
.eight-section {
  text-align: center; /* Center-aligns text content */
  padding: 50px 0; /* Adds vertical padding of 50px and no horizontal padding */
  /* background-color: #faf5f7;  */
  background-color: #ffffff; /* Sets background color */
}

/* Styling for the messages within the Swiper slides */
.swiper-slide-msg {
  padding: 3rem 4rem; /* Adds padding around the message content */
  background: #f1f1f1; /* Sets the background color of the message */
  border-radius: 1rem; /* Applies border radius to soften edges */
  text-align: left; /* Left-aligns the text content */
  box-shadow: 0px 0px 20px 0px #69DC9E33; /* Adds a box shadow to the message */
  position: relative; /* Positioned relative to its normal position */
  height: 100%;
}

/* Styling for the pseudo-element before the message */
.swiper-slide-msg::before {
  content: ""; /* Generates a pseudo-element */
  position: absolute; /* Positioned relative to the message */
  bottom: -3rem; /* Positions the pseudo-element at the bottom */
  left: 50%; /* Positions the pseudo-element at the horizontal center */
  transform: translateX(-50%); /* Centers the pseudo-element horizontally */
  border: 1.5rem solid #fff; /* Sets the border of the pseudo-element */
  border-color: transparent; /* Makes the border transparent */
  border-top-color: #69DC9E40; /* Sets the top border color */
}

/* Styling for the paragraph elements within the messages */
.swiper-slide-msg p {
  font-weight: 500; /* Sets the font weight of the paragraph text */
  margin-left: 10px; /* Adds left margin to the paragraph */
}

/* Styling for the icon before the paragraph */
.swiper-slide-msg p::before {
  content: "\f10d"; /* Inserts an icon using Font Awesome */
  font-family: "Font Awesome 6 Free"; /* Specifies the font family for the icon */
  font-weight: 900; /* Sets the font weight of the icon */
  position: absolute; /* Positioned relative to its parent */
  top: 2rem; /* Positions the icon at the top */
  left: 2rem; /* Positions the icon at the left */
  font-size: 30px; /* Sets the size of the icon */
  color: #69DC9E; /* Sets the color of the icon */
}

/* Styling for the icon after the paragraph */
.swiper-slide-msg p::after {
  content: "\f10e"; /* Inserts an icon using Font Awesome */
  font-family: "Font Awesome 6 Free"; /* Specifies the font family for the icon */
  font-weight: 900; /* Sets the font weight of the icon */
  position: absolute; /* Positioned relative to its parent */
  bottom: 2rem; /* Positions the icon at the bottom */
  right: 2rem; /* Positions the icon at the right */
  font-size: 30px; /* Sets the size of the icon */
  color: #69DC9E; /* Sets the color of the icon */
}

/* Styling for the container of client data */
.swiper-client-data {
  justify-content: center; /* Centers the content horizontally */
  align-items: center; /* Centers the content vertically */
  display: flex; /* Displays the content as a flex container */
  margin-top: 1rem; /* Adds top margin to the container */
  gap: 2.2rem; /* Sets the gap between items */
}

/* Styling for the images within the Swiper slides */
.swiper-slide img {
  max-width: 10rem; /* Sets the maximum width of the images */
  height: 7rem; /* Sets the height of the images */
  border-radius: 50%; /* Applies border radius to create circular images */
  border: .4rem solid #69DC9E40; /* Adds a border around the images */
}

/* Styling for the details of client data */
.client-data-details h1 {
  color: #000; /* Sets the color of the heading */
  font-size: 18px; /* Sets the font size of the heading */
  font-weight: 700; /* Sets the font weight of the heading */
  margin-bottom: 0; /* Removes bottom margin from the heading */
}

.client-data-details p {
  color: #000; /* Sets the color of the paragraph text */
  font-size: 14px; /* Sets the font size of the paragraph text */
  margin-bottom: 0; /* Removes bottom margin from the paragraph */
}
/* eighty section css */

/* ninth section css */
.ninth-section {
  text-align: center; /* Center-aligns text content */
  padding: 50px 0; /* Adds vertical padding of 50px and no horizontal padding */
  background-color: #f1f1f1; /* Applies a light gray background */
}

/* Styling for the container of FAQ boxes */
.box-container2 {
  /* display: flex; */ /* Commented out to remove flex display */
  justify-content: center; /* Centers the content horizontally */
  max-width: 1320px; /* Sets the maximum width of the container */
  margin: 0 auto; /* Centers the container horizontally */
  margin-top: 30px; /* Adds top margin to the container */
}

/* Styling for the FAQ boxes */
.faq-list .faq-box {
  background: #ffffff; /* Sets the background color of the FAQ box with opacity */
  padding: 15px; /* Adds padding around the content of the FAQ box */
  border-radius: 3px; /* Applies border radius to soften edges */
  margin-bottom: 15px; /* Adds bottom margin to separate FAQ boxes */
  animation: 0.2s ease-in-out; /* Applies animation with ease-in-out timing */
  transition: 0.2s ease-in; /* Applies transition effect with ease-in timing */
  max-height: 52px; /* Sets the maximum height of the FAQ box */
  overflow: hidden; /* Hides overflowing content */
  transition: max-height 0.3s ease; /* Smooth transition effect for max-height property */
}

/* Styling for active FAQ boxes */
.faq-list .faq-box.faq-box-active {
  max-height: 1000px !important; /* Sets the maximum height to display full content for active FAQ boxes */
}

/* Styling for the top section of FAQ boxes */
.faq-list .faq-box .top {
  border: 0; /* Removes border */
  outline: none; /* Removes outline */
  width: 100%; /* Sets the width to 100% */
  background: 0 0; /* Removes background */
  animation: 0.2s ease-in-out; /* Applies animation with ease-in-out timing */
  transition: 0.2s ease-in; /* Applies transition effect with ease-in timing */
}

/* Additional styling for the top section of FAQ boxes */
.faq-list .faq-box .top {
  text-align: justify; /* Justifies text content */
  display: flex; /* Displays content as flex */
  align-items: center; /* Centers items vertically */
  justify-content: space-between; /* Distributes space evenly between items */
  animation: 0.2s ease-in-out; /* Applies animation with ease-in-out timing */
  transition: 0.2s ease-in; /* Applies transition effect with ease-in timing */
  cursor: pointer; /* Changes cursor to pointer on hover */
}

/* Styling for the description section of FAQ boxes */
.faq-list .faq-box .faq-desc p {
  margin-top: 10px; /* Adds top margin to separate content */
  margin-left: 6px; /* Adds left margin to indent content */
  color: #262626; /* Sets the color of the text */
  font-size: 14.5px; /* Sets the font size of the text */
  animation: 0.2s ease-in-out; /* Applies animation with ease-in-out timing */
  transition: 0.2s ease-in; /* Applies transition effect with ease-in timing */
  text-align: justify; /* Aligns text to the start (left) */
}

/* Styling for the title of FAQ boxes */
.faq-list .faq-box h1 {
  font-size: 15px; /* Sets the font size of the title */
  font-weight: 500; /* Sets the font weight of the title */
  width: 100%; /* Sets the width to 100% */
  text-overflow: ellipsis; /* Specifies how overflowed content is signaled */
  white-space: nowrap; /* Prevents wrapping of content */
  overflow: hidden; /* Hides overflowing content */
  color: #000; /* Sets the color of the text */
  margin-left: 10px; /* Adds left margin to indent content */
}

/* Styling for the SVG icons within FAQ boxes */
.faq-list .faq-box svg path {
  color: #000; /* Sets the color of the SVG icons */
  fill: currentColor; /* Fills the SVG icons with the current color */
}

/* Footer styling */
footer {
  width: 100%; /* Sets the width of the footer to 100% */
  /* background: #334a5c; Sets the background color of the footer */
  background: #ffffff; /*Sets the background color of the footer */
  color: #fff; /* Sets the text color to white */
  padding: 73px 0 30px; /* Sets padding for the footer */
  /* border-top-left-radius: 125px; Applies border radius to the top left corner */
  font-size: 13px; /* Sets the font size */
  line-height: 20px; /* Sets the line height */
}

/* Row container within the footer */
footer .row {
  width: 95%; /* Sets the width of the row */
  margin: auto; /* Centers the row horizontally */
  display: flex; /* Displays the row as flex */
  flex-wrap: wrap; /* Allows items to wrap */
  align-items: flex-start; /* Aligns items at the start */
  justify-content: space-between; /* Distributes space between items */
}

/* Styling for columns within the footer */
footer .row .col:nth-child(2), .col:nth-child(3) {
  flex-basis: 15%; /* Sets the flex basis for specific columns */
}

footer .row .col {
  flex-basis: 25%; /* Sets the flex basis for columns */
  padding: 10px; /* Adds padding around column content */
}

/* Styling for logos within columns */
footer .row .col .logo {
  /* Add specific styling for logos */
}

/* Styling for headings within columns */
footer .row .col h3 {
  width: fit-content; /* Sets the width to fit the content */
  /* margin-bottom: 40px; Adds bottom margin */
  position: relative; /* Sets position to relative for child elements */
  font-size: 20px;
  color: #000;
}

/* Styling for paragraphs within columns */
footer .row .col p {
  margin: 0; /* Resets margins */
  color: #000;
}

/* Styling for email addresses within columns */
footer .row .col .email-id {
  width: fit-content; /* Sets the width to fit the content */
  /* border-bottom: 1px solid #ccc; Adds bottom border */
  margin: 20px 0; /* Adds top and bottom margin */
  font-size: 15px;
  font-weight: 500;
  color: black;
}

/* Styling for unordered lists within columns */
footer .row .col ul {
  padding: 0; /* Resets padding */
  margin: 0; /* Resets margin */
}

/* Styling for list items within unordered lists */
footer .row .col ul li {
  list-style: none; /* Removes default list style */
  margin-bottom: 12px; /* Adds bottom margin */
}

/* Styling for links within list items */
footer .row .col ul li a {
  text-decoration: none; /* Removes underline */
  color: #000; /* Sets text color to white */
}

.col a {
  text-decoration: none; /* Remove underline */
}


/* Styling for forms within columns */
footer .row .col form {
  padding-bottom: 15px; /* Adds bottom padding */
  display: flex; /* Displays form as flex */
  align-items: center; /* Aligns items vertically */
  justify-content: space-between; /* Distributes space between items */
  border-bottom: 1px solid #ccc; /* Adds bottom border */
  margin-bottom: 50px; /* Adds bottom margin */
}

/* Styling for icons within forms */
footer .row .col form .far {
  font-size: 18px; /* Sets the font size */
  margin-right: 10px; /* Adds right margin */
}

/* Styling for inputs within forms */
footer .row .col form input {
  width: 100%; /* Sets the width to 100% */
  background: transparent; /* Sets background to transparent */
  color: #ccc; /* Sets text color */
  border: 0; /* Removes border */
  outline: none; /* Removes outline */
}

/* Styling for buttons within forms */
footer .row .col form button {
  background: transparent; /* Sets background to transparent */
  border: 0; /* Removes border */
  outline: none; /* Removes outline */
  cursor: pointer; /* Changes cursor to pointer */
}

/* Styling for icons within buttons */
footer .row .col form button .fas {
  font-size: 16px; /* Sets the font size */
  color: #ccc; /* Sets text color */
}

/* Styling for social icons within columns */
footer .row .col .social-icons .fab {
  width: 40px; /* Sets width */
  height: 40px; /* Sets height */
  border-radius: 50%; /* Applies border radius */
  line-height: 40px; /* Sets line height */
  font-size: 20px; /* Sets font size */
  color: #000; /* Sets text color */
  background: #fff; /* Sets background color */
  margin-right: 15px; /* Adds right margin */
  cursor: pointer; /* Changes cursor to pointer */
  text-align: center; /* Centers text */
}

/* Hover effect for Facebook icon */
footer .row .col .social-icons .fa-facebook-f:hover {
  color: #0866ff; /* Changes color on hover */
}

/* Hover effect for Twitter icon */
footer .row .col .social-icons .fa-linkedin:hover {
  color: #029eec; /* Changes color on hover */
}

/* Hover effect for Instagram icon */
footer .row .col .social-icons .fa-instagram:hover {
  color: #a32eb8; /* Changes color on hover */
}

/* Hover effect for YouTube icon */
footer .row .col .social-icons .fa-youtube:hover {
  color: #f70f1b; /* Changes color on hover */
}

/* Styling for horizontal rule */
footer hr {
  width: 90%; /* Sets the width of the horizontal rule */
  border: 0; /* Removes default border */
  border-bottom: 1px solid #ccc; /* Sets bottom border */
  margin: 20px auto; /* Adds top and bottom margin */
}

/* Styling for copyright text */
footer .copyright {
  text-align: center; /* Centers text */
  margin: 0; /* Resets margin */
  padding: 0; /* Resets padding */
  color: #000;
}

/* Styling for underline effect */
footer .row .col h3 .underline {
  width: 100%; /* Sets the width to 100% */
  height: 5px; /* Sets the height of the underline */
  background: #767676; /* Sets the background color of the underline */
  border-radius: 3px; /* Applies border radius */
  position: absolute; /* Sets position to absolute */
  top: 35px; /* Positions the underline */
  left: 0; /* Positions the underline */
  overflow: hidden; /* Hides overflow */
}
footer .row .col h3 .underline span {
  width: 15px; /* Sets the width of the moving span */
  height: 100%; /* Sets the height of the moving span */
  background: #fff; /* Sets the background color of the moving span */
  border-radius: 3px; /* Applies border radius */
  position: absolute; /* Sets position to absolute */
  top: 0; /* Positions the moving span at the top */
  left: 10px; /* Positions the moving span 10px from the left */
  animation: moving 2s linear infinite; /* Applies the moving animation */
}

@keyframes moving {
  0% {
    left: -20px; /* Moves the span 20px to the left */
  }
  100% {
    left: 100%; /* Moves the span to the right edge of its container */
  }
}


/* About Us Section */
.about-us {
  height: 392px;
  width: 100%;
}

.about-us-content {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0)), 
              url('/public/finalize/About Us/aboutUs_1600_400.png') center top no-repeat; */
  /* background-size: contain; Ensures the image covers the entire div */
}

.about-us img {
  width: 100%;
  height: 400px;
}







.raj {
  position: relative; /* Make the parent relative for absolute positioning of the overlay */
}

.raj img {
  display: block; /* Ensures there's no extra space below the image */
  width: 100%; /* Make image responsive */
  height: auto; /* Maintain aspect ratio */
}

.raj::before {
  content: ""; /* Necessary for the pseudo-element */
  position: absolute; /* Position the overlay over the image */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.73), rgba(255, 255, 255, 0)); /* Your gradient */
  pointer-events: none; /* Allow clicks to pass through to the image */
}






.about-us-text {
  font-size: 50px;
  font-weight: bold;
  color: #fff;
}

/* Responsive styling */
@media (max-width: 1000px) {
  .about-us {
    height: 300px; /* Adjust height for larger screens */
  }

  .about-us-text {
    font-size: 40px; /* Adjust font size for larger screens */
  }
  .about-us img {
    width: 100%;
    height: 143px;
  }
}

@media (max-width: 768px) {
  .about-us {
    height: 250px; /* Adjust height for tablets */
  }

  .about-us-text {
    font-size: 30px; /* Adjust font size for tablets */
  }
  .about-us img {
    width: 100%;
    height: 143px;
  }
  .raj img {
    display: block; /* Ensures there's no extra space below the image */
    width: 100%; /* Make image responsive */
    height: 160px; /* Maintain aspect ratio */
  }
}

@media (max-width: 576px) {
  .about-us {
    height: 145px; /* Adjust height for mobile devices */
  }

  .about-us-text {
    font-size: 20px; /* Adjust font size for mobile devices */
  }
  .about-us img {
    width: 100%;
    height: 143px;
  }
}

/* About Us Section */
.aboutus-section {
  display: flex;
  justify-content: center;
  max-width: 1220px;
  margin: 70px auto 50px auto;
  padding: 0 20px;
  flex-wrap: wrap;
}

/* Content within .aboutus-section */
.aboutus-section-content {
  width: 45%;
  min-width: 300px;
}

/* Styling for the image view within .aboutus-section */
.aboutus-section-img-view {
  border: 4px solid #ff1a41;
  /* margin-right: 30px; */
  flex-shrink: 0;
  width: 36%;
  height: 40%;
}

/* Styling for images within .aboutus-section-img */
.aboutus-section-img {
  padding: 10px;
}

/* Responsive adjustments for images within .aboutus-section-img */
.aboutus-section-img img {
  width: 100%;
}

/* Styling for heading within .aboutus-section */
.aboutus-section-heading {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 0;
  text-transform: capitalize;
  width: 100%;
}

/* Styling for line after heading within .aboutus-section */
.aboutus-section-heading::after {
  content: "";
  border-bottom: 4px solid #ff1a41;
  height: 10px;
  width: 73px;
  display: block;
  margin-top: -4px;
  margin-bottom: 21px;
}

/* Styling for subheading within .aboutus-section */
.aboutus-section-subheading {
  margin-bottom: 10px;
  width: 100%;
  text-align: justify;
}

/* Responsive adjustments for medium-sized screens */
@media (max-width: 768px) {
  .aboutus-section {
    flex-direction: column;
    align-items: center;
  }

  .aboutus-section-img-view {
    /* margin-right: 0px; */
    margin-bottom: 20px;
    /* margin-left: 36px; */
  }

  .aboutus-section {
    display: flex;
    justify-content: center;
    max-width: 1220px;
    margin: 70px auto 2px auto;
    padding: 0 20px;
    flex-wrap: wrap;
  }

  .aboutus-section-content {
    width: 100%;
    margin-top: 15px;
  }

  .aboutus-section-heading {
    font-size: 30px;
  }

  .aboutus-section-heading::after {
    margin-bottom: 15px;
  }
  .col .row {
    display: flex;
    justify-content: center;
  }
  .col .row .fff {
    margin-top: 10px;
  }
  .aboutus-section-img-view {
    width: 60%;
  }
  .aboutus-section-img {
    width: 100%;
  }
  .aboutus-section-content {
    margin-left: 0px !important;
  }
}
.aboutus-section-content {
  margin-left: 40px;
}

/* Responsive adjustments for small-sized screens */
@media (max-width: 576px) {
  .aboutus-section-heading {
    font-size: 24px;
  }

  .aboutus-section-heading::after {
    width: 50px;
  }

  .aboutus-section-subheading {
    font-size: 14px;
  }
}

/* about-hero-text */

/* Modal Background Styling */
.modalBG {
  align-items: center;
  background: #00000061;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: auto;
  position: fixed;
  top: 0;
  transition: .3s ease-in-out;
  width: 100%;
  opacity: 1;
  z-index: 200;
}

/* Modal Background Hidden State */
.modalBG.none {
  opacity: 0;
  z-index: -1;
}

/* Modal White Container Styling */
.modalBG .modalWhite {
  background-color: #fff;
  border-radius: 10px;
  height: 40%;
  min-height: 590px;
  width: 450px;
}

/* Modal White Content Styling */
.modalBG .modalWhite .modalWhite-content {
  padding: 40px 30px 40px 30px;
}
/* Top Header Styling */
.modalBG .modalWhite .modalWhite-content .top-header {
  display: flex;
  justify-content: space-between;
}

/* Top Header Heading Styling */
.modalBG .modalWhite .modalWhite-content .top-header h3 {
  color: #1d1d1b;
  font-size: 20px;
  font-weight: 700;
}

/* Top Header Close Button Styling */
.modalBG .modalWhite .modalWhite-content .top-header .top-header-close-btn {
  border: 0;
  background: 0 0;
  padding: 0;
  margin: 0;
  height: 0;
}

/* Top Header Close Button Image Styling */
.modalBG .modalWhite .modalWhite-content .top-header img {
  width: 23px;
  height: 23px;
}

/* Form View Styling */
.form-view {
  margin-top: 20px;
}

/* Form View Label Styling */
.form-view label {
  color: #222 !important;
  font-weight: 500 !important;
  margin-bottom: 3px !important;
  margin-left: 3px !important;
}

/* Form View Input Styling */
.form-view input#mobile {
  padding-left: 50px !important;
}
.india-img {
  position: absolute;
  top: 39px;
  left: 13px;
  width: 20px;
  height: 20px;
}
.line {
  position: absolute;
  top: 28px;
  left: 42px;
  /* width: 0px; */
  height: 43px;
  border-right: 1px solid #ced4da;
}
.form-view .form-control.alertInput {
  border: 1px solid #e11c1c !important;
}
.form-view .form-control {
  appearance: none !important;
  background-clip: padding-box !important;
  /* background-color: #f0f0f0 !important; */
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  color: #212529 !important;
  display: block !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  padding: .375rem .75rem !important;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
  width: 100% !important;
  height: 45px;
}
/* .form-control:focus {
  box-shadow: none !important;
} */
button:focus {
  outline: none !important;
  border: 0 !important;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.updates {
  display: flex;
  align-items: center;
  gap: .5rem;
  margin-bottom: 1rem;
  margin-top: -10px;
  margin-left: 5px;
  cursor: pointer;
}
.updates label {
  color: #222 !important;
  font-weight: 400 !important;
  margin-bottom: 0px !important;
  margin-left: -2px !important;
  font-size: 13px;
  user-select: none;
}
.updates input[type="checkbox"] {
  position: relative;
  width: 15.5px;
  height: 15px;
  background-color: #fff;
  border-radius: .2rem !important;
  margin-top: -2px;
  appearance: none;
  cursor: pointer;
  border: 1px solid #ff1a41;
}
.updates input[type="checkbox"]:checked {
  background-color: #ff1a41;
}
.updates input[type="checkbox"]::before {
  content: '';
  position: absolute;
  top: 1.2px;
  left: 5px;
  width: 4.5px;
  height: 9.5px;
  border: 2px solid transparent;
  border-left: none;
  border-top: none;
  transform: rotate(45deg) scale(1);
}
.updates input[type="checkbox"]:checked::before {
  border-color: #fff;
  animation: checkAnim .2s ease;
}
@keyframes checkAnim {
  from {
    transform: rotate(45deg) scale(0);
  }
}
button.form-btn:hover {
  /* background: #69DC9E; */
}
button:disabled {
  background-color: #d7d7d7 !important;
  color: light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3)) !important;
  border-color: light-dark(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3)) !important;
}
button.form-btn {
  /* background: linear-gradient(63deg, #4162ff, #4162ff 18.33%, #5473ff 28.33%, #5473ff 53.66%, #6b83f0 63.66%, #6b83f0); */
  background: #ff1a41;
  border-radius: 3px !important;
  /* color: #fff !important; */
  /* margin-left: 47px !important; */
  padding: 7px 10px !important;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 43px;
  animation: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  margin-top: 20px;
}
button.form-btn svg {
  width: 16px;
  height: 16px;
  margin-left: 10px;
}
button.form-btn svg path {
  color: #fff;
  fill: currentColor;
}
button.form-btn ul {
  margin: 0;
  padding: 0;
}
button.form-btn ul li {
  list-style: none;
  width: 20px;
  height: 12px;
  /* background-color: #262626; */
  /* margin: 0 10px; */
  /* border-radius: 50%; */
  transition: .5s;
  margin-left: 0px;
}
button.form-btn:hover ul li {
  margin-left: 10px;
}
button.form-btn ul li span {
  position: absolute;
  width: 10px;
  height: 10px;
  border: 2.5px solid #fff;
  transition: .5s;
}
button.form-btn ul li.next span {
  /* top: 50%;
  right: 20%; */
  transform: rotate(-45deg);
  border-left: none;
  border-top: none;
}
button.form-btn ul li.next span:before {
  content: '';
  position: absolute;
  width: 16px;
  height: 2.5px;
  background: #fff;
  transform-origin: right;
  transform: rotate(45deg) translate(-4px, 13.5px) scale(0);
  transition: .5s;
}
button.form-btn:hover ul li.next span:before {
  transform: rotate(45deg) translate(-4px, 13.5px) scale(1);
}
/* modal css */

/* Privacy Policy css */.privacyPolicy {
  justify-content: center;
  max-width: 1120px;
  margin: 50px auto 0 auto;
  padding: 0 20px;
}

.privacyPolicy .section-heading {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 0;
  text-transform: capitalize;
  text-align: center;
  color: #3d3d3d;
}

.privacyPolicy .section-heading::after {
  content: "";
  border-bottom: 4px solid #3d3d3d;
  height: 10px;
  width: 73px;
  display: block;
  margin: -4px auto 21px auto;
}

.privacyPolicy .paragraph {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
  text-align: justify;
  padding: 20px 0;
}

.privacyPolicy .paragraph p {
  margin-bottom: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .privacyPolicy .section-heading {
    font-size: 30px;
  }

  .privacyPolicy .section-heading::after {
    width: 60px;
    height: 8px;
  }

  .privacyPolicy .paragraph {
    font-size: 14px;
    padding: 10px 0;
  }
}

@media (max-width: 576px) {
  .privacyPolicy .section-heading {
    font-size: 24px;
  }

  .privacyPolicy .section-heading::after {
    width: 50px;
    height: 6px;
  }

  .privacyPolicy .paragraph {
    font-size: 12px;
  }
}

.privacyPolicy .section-heading .paragraph {

}
/* Privacy Policy css */



/* Responsive adjustments */
@media (max-width: 768px) {
  .our-services-box {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
    width: 180px;
    height: 200px;
    padding: 15px;
    justify-content: center;
    align-items: center;
    display: grid;
    transition: 0.5s ease-in-out;
  }
  .our-services-box:hover {
    transform: scale(1);
    margin-right: 10px;
    margin-left: 0px;
    transition: 0.3s ease-in-out;
  }
  .our-services-box:hover .our-services-title {
    color: #000;
    transition: 0.3s ease-in-out;
  }
}
  
@media (max-width: 206px) {
  .package {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #ff1a41;
    /* margin: 0 2rem; */
    padding: 1rem 0px 8px 0;
    margin-left: 0px;
  }
}
@media (max-width: 576px) {
  #root {
    width: 100.5%;
  }
  .fifth-section {
    /* max-width: 358px; */
    text-align: center;
    padding: 50px 30px;
    /* background: #69dc9e; */
    margin: 0 auto;
  }
  .box-container1 {
    justify-content: flex-start;
    overflow-x: scroll;
    scrollbar-width: none;
    display: grid;
    grid-template-columns: auto auto auto auto;
    margin-top: 0px;
  }
  .section-heading {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 0px;
    text-transform: uppercase;
  }
  .section-subheading {
    font-size: 13px;
    margin-bottom: 30px;
  }
  .HowItWorks_stepsBx__HY4oU {
    align-items: center;
    animation: 0.2s ease-in-out;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 502px;
    position: relative;
    transition: 0.2s ease-in;
    width: 98%;
    padding: 0;
    margin-top: -2px;
  }
  .HowItWorks_stepsBx__HY4oU li label {
    margin-left: -50px;
    position: absolute;
    left: 87%;
    top: 41px;
    white-space: break-spaces;
    width: 81px;
    font-size: 10px;
    /* text-align: revert-layer; */
    animation: 0.2s ease-in-out;
    transition: 0.2s ease-in;
    font-weight: 500;
  }
  .box-paragraph {
    font-size: 15px;
    color: #333;
    font-weight: 500;
    text-align: justify;
  }
  .box {
    width: 600px;
    padding: 1px;
    margin: 20px 20px 0 20px;
    background-color: #fff0;
    border-radius: 10px;
    box-shadow: none;
  }
  
  
  
  
 
  
   
    
  .box3-container {
    display: grid; /* Use grid layout for mobile */
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    gap: 15px; /* Space between items */
    margin-right:10px;  /*rajesh*/
    margin-left: 10px;
    /* text-align: justify; */
    align-items: stretch;
  }
  
  .box3 {
    width: 100%; /* Ensure boxes take full width in the grid */
    margin: 0; /* Remove left margin */
    height: 300px;
    
  }     
      
.box3 .box-title {
    font-size: 19px;
    font-weight: 900;
    margin-bottom: 10px;
    color: #000;
  }
  .box3 .box-subtitle {
    font-size: 14px;
    color: #000;
    text-align: center;
  }    
	
	 
	  
	   
	    
	     
	      
	       
		
		 
		  
		   
		    
		     
		      
		        
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  .box2-container {
    display: grid;
    justify-content: flex-start;
    max-width: 1320px;
    margin: 0 0px 0 0px;
    grid-template-columns: auto auto auto auto;
    overflow-x: scroll;
    scrollbar-width: none;
  }
  .box2 {
    align-items: center;
    /* background: linear-gradient(63deg, #f897a7, #f897a7 18.33%, #fb94a6 28.33%, #fb94a6 53.66%, #f195a4 63.66%, #f195a4); */
    background: #faf5f7;
    border: 0;
    border-radius: 5px;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    padding: 30px 30px;
    width: 250px;
    height: 400px;
    transition: 0.3s ease-in-out;
    overflow: hidden;
  }
  .box2 .box-title {
    font-size: 16px;
    font-weight: 900;
    margin-bottom: 10px;
    color: #000;
  }
  .box2 .box-subtitle {
    font-size: 16px;
    color: #000;
    text-align: justify;
    /* text-align-last: justify; */
  }
  .sixth-section .section-heading {
    font-size: 23px;
    font-weight: 700;
    margin-bottom: .5rem;
    color: #333;
  }
  .faq-list {
    margin: 0 10px;
  }
  .faq-list .faq-box h1 {
    font-size: 15px;
    font-weight: 500;
    width: 80%;
    text-overflow: clip;
    white-space: normal;
    overflow: hidden;
    color: #000;
    margin-left: 10px;
    text-align: left;
  }
  .faq-list .faq-box {
    background: #ffffff;
    padding: 15px;
    border-radius: 3px;
    margin-bottom: 15px;
    animation: 0.2s ease-in-out;
    transition: 0.2s ease-in;
    max-height: 55px;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }
  .box-container2 {
    /* display: flex; */
    justify-content: center;
    max-width: 1320px;
    margin: 0 auto;
    margin-top: 21px;
  }
  .eight-section .section-heading {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 0px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  .swiper-slide-msg p {
    font-weight: 500;
    margin-left: -4px;
    font-size: 15px;
    text-align: justify;
  }
  .swiper-slide-msg p::before {
    content: "\f10d";
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    position: absolute;
    top: 2rem;
    left: 2rem;
    font-size: large;
    color: #69DC9E;
  }
  .swiper-slide-msg p::after {
    content: "\f10e";
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    font-size: 22px;
    color: #69DC9E;
  }
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #ff1a41 !important;
}


.mob-footer {
  display: none;
}

@media (max-width: 768px) {
  .mob-footer {
    display: flex;
    width: 100%;
    position: sticky;
    bottom: 0;
    background: #fff;
    height: 30px;
    z-index: 100000;
  }
  .mob-footer .row {
    width: 100%;
  }
  .mob-footer .bg1 {
    width: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .mob-footer .bg1 a {
    font-size: 15px;
    font-weight: 500;
    color: #000;
  }
  .mob-footer .bg2 {
    width: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .mob-footer .bg2 a {
    background-color: #ff1a41;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-items: center;
    justify-content: center;
    display: flex;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
  }
}
@media (max-width: 206px) {
  .mob-footer {
    display: flex;
    width: 100%;
    position: sticky;
    bottom: 0;
    background: #fff;
    height: 30px;
    z-index: 100000;
  }
  .mob-footer {
    width: 100%;
  }
  .mob-footer .bg1 {
    width: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .mob-footer .bg1 a {
    font-size: 15px;
    font-weight: 500;
    color: #000;
  }
  .mob-footer .bg2 {
    width: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .mob-footer .bg2 a {
    background-color: #ff1a41;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-items: center;
    justify-content: center;
    display: flex;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
  }
}
@media (max-width: 576px) {
  .mob-footer {
    display: flex;
    width: 100%;
    position: sticky;
    bottom: 0;
    background: #fff;
    height: 30px;
    z-index: 100000;
  }
  .mob-footer {
    width: 100%;
  }
  .mob-footer .bg1 {
    width: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .mob-footer .bg1 a {
    font-size: 15px;
    font-weight: 500;
    color: #000;
  }
  .mob-footer .bg2 {
    width: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .mob-footer .bg2 a {
    background-color: #ff1a41;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-items: center;
    justify-content: center;
    display: flex;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
  }
}